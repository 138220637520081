import BarChart from './components/BarChart'

const Home = () => {
  const a1 = [20, 30, 10]
  const a2 = [40, 30, 20]
  return (
    <div>
      <BarChart title={'三大框架使用度'} data={a1} />
      <BarChart title={'三大框架满意度'} data={a2} />
    </div >
  )
}

export default Home