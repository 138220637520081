import { useEffect, useState } from 'react'
import { GetchannelsAPI } from '@/apis/article'

function useChannels() {
  const [channels, setChannels] = useState([])
  useEffect(() => {
    async function Getchannels() {
      const res = await GetchannelsAPI()
      setChannels(res.data.channels)
    }
    Getchannels()
  }, [])

  return {
    channels
  }
}

export { useChannels }