import { request } from "@/utils"

export function LoginAPI(formdata) {
  return request({
    url: '/authorizations',
    method: 'post',
    data: formdata
  })
}

export function GetuserInfoAPI(formdata) {
  return request({
    url: '/user/profile',
    method: 'get'
  })
}