import { request } from "@/utils";

export function GetchannelsAPI() {
  return request({
    url: '/channels',
    method: 'get'
  })
}

export function PublishAPI(formdata) {
  return request({
    url: '/mp/articles?draft=false',
    method: 'post',
    data: formdata
  })
}

export function GetArticleListAPI(params) {
  return request({
    url: '/mp/articles',
    method: 'get',
    params
  })
}

// 删除文章
export function delArticleAPI(id) {
  return request({
    url: `/mp/articles/${id}`,
    method: 'DELETE'
  })
}

// 获取文章详情
export function GetArticleById(id) {
  return request({
    url: `/mp/articles/${id}`
  })
}

// 更新文章表单
export function updateArticleAPI(data) {
  return request({
    url: `/mp/articles/${data.id}?draft=false`,
    method: 'PUT',
    data
  })
}